import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import Header from '../components/Header/HeaderProjects'
import Button from '../components/Button/Button'

const ProjectPage = ({ data }) => {
  const { title, customer, tasks, techStack, thumbnail, abstract, projectCategory } = data.contentfulProject
  const content1 = data.contentfulProject.childContentfulProjectContentPart1RichTextNode.json
  const content2 = data.contentfulProject.childContentfulProjectContentPart2RichTextNode.json

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className='mb-6'>{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className='pl-10 mb-6'>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className='list-item-with-arrow pl-4'>{children}</li>
    }
  }

  return (
    <Layout>
      <SEO title={title} />
      <Header color='grey' className="stage-projekte">
          <div className='flex w-full max-w-4xl mt-8 md:mt-16 lg:mt-32'>
          <p className='category-title'>{projectCategory}</p>
          </div>
          <div className='flex md:w-6/12 max-w-4xl md:pr-8 lg:pr-16 mt-8 md:mt-0 md:mb-32 lg:mb-40'>
            <h1 className='stage-title title-project'>{title}</h1>
          </div>
          <div className='flex md:w-6/12 md:pl-8 lg:pl-16 md:pr-8 lg:pr-16 pb-16 md:pb-0 max-w-4xl mt-8 md:mt-0 mb-8 md:mb-0 '>
            <p className='abstract'>{abstract.abstract}</p>
          </div>

      </Header>
      <div className='flex w-full flex-wrap justify-center'>
        <div className='flex flex-col flex-wrap max-w-6xl py-24 px-6 lg:px-0'>
          <div className='w-full -mt-40 relative'>
            <img src={`${thumbnail.fluid.src}&w=1600&q=86`} alt={title} className='fit-image' />
          </div>
          <div className='flex w-full flex-wrap pt-20 justify-between'>
            <div className='w-full md:w-6/12 lg:w-7/12 mb-10 lg:mb-0'>
              {documentToReactComponents(content1, options)}
            </div>
            <div className='flex flex-col w-full md:w-5/12 lg:w-4/12 text-swenden-purple project-overview'>
              <div className='mb-6'>
                <div className='mb-1 font-element'>Kunde: </div>
                  <div className='font-headline mb-6'>{customer.name}</div>
                <div className='border-b border-swenden-purple'></div>
              </div>
              {customer.referencePage &&
                <div className='mb-6'>
                  <div className='mb-1 font-element'>Link:</div>
                  <a href={customer.referencePage} className='block font-headline mb-6' target='_blank' rel='noopener noreferrer'>{customer.referencePage}</a>
                  <div className='border-b border-swenden-purple'></div>
                </div>
              }
              <div className='mb-6'>
                <div className='mb-1 font-element'>Unsere Aufgaben:</div>
                <div className='font-headline mb-6'>{tasks.join(', ')}</div>
                <div className='border-b border-swenden-purple'></div>
              </div>
              <div className='mb-6'>
                <div className='mb-1 font-element'>Technologien:</div>
                <div className='font-headline mb-6'>{techStack.join(', ')}</div>
              </div>
            </div>
          </div>
          <div>
            {documentToReactComponents(content2, options)}
          </div>
          <div className="flex w-full mt-16">
            <Button to='/projects' withArrow arrowStyle='arrow-prev' className='p-4 mr-8 xs:mt-8 md:mt-0 btn-color-text'>zurück zur Übersicht</Button>
          </div>
        </div>
        </div>
    </Layout>
  )
}

export default ProjectPage

export const projectQuery = graphql`
  query ($slug: String!) {
    contentfulProject(slug: {eq: $slug}) {
      title
      projectCategory
      abstract {
        abstract
      }
      thumbnail {
        fluid {
          src
        }
      }
      customer {
        name
        referencePage
      }
      tasks
      techStack
      childContentfulProjectContentPart1RichTextNode {
        json
      }
      childContentfulProjectContentPart2RichTextNode {
        json
      }
    }
  }
`
