import React from 'react'
import PropTypes from 'prop-types'

import './Header.css'

const Header = ({ className, titleBig, color, textcolor, children }) => {
  return (
    <>
      <header className={`w-full px-6 pb-6 pt-0 flex justify-center bg-${color} ${className}`}>
        <div className={`flex w-full max-w-6xl flex-wrap`}>
          {children}
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  titleBig: PropTypes.string,
  color: PropTypes.string,
  textcolor: PropTypes.string,
  children: PropTypes.node,
}

Header.defaultProps = {
  color: 'cream',
}

export default Header
